<template>
    <div class="main-container">
      <h5 class="pageTitle" v-html="pageTitle"></h5>
      <mdb-container class="search-container">
        <mdb-row>
          <div class="ml-3">
            <mdb-select
                class="search-select"
                outline
                resetBtn
                v-model="selectOptions"
                placeholder="검색 항목"
                @getValue="getSelectValue"/>
          </div>
          <mdb-col>
            <mdb-input type="text" v-model="search" outline placeholder="검색어를 입력하세요."></mdb-input>
          </mdb-col>
        </mdb-row>
      </mdb-container>
      <mdb-container>
        <mdb-datatable-2
            v-model="data"
            class="text-center"
            striped
            bordered
            hover
            :searching="{value: search, field: fieldName} "            
            noFoundMessage="데이터가 없습니다."/>       
      </mdb-container>
    </div>
  </template>
  
  <script>
  import {mdbCol, mdbContainer, mdbDatatable2, mdbInput, mdbRow, mdbSelect} from "mdbvue";
  import log_list from "@/assets/data/columns/log_list";
  import axios from "@/axios";
  
  export default {
    name: "LogList",
    components: {
      mdbContainer,
      mdbDatatable2,
      mdbRow,
      mdbCol,
      mdbInput,
      mdbSelect,
    },
    data() {
      return {
        token: this.$store.state.token.access_token,
        pageTitle: '기체비행기록부 발급',
        data: {
          columns: log_list,
          rows: []
        },
        search: '',
        fieldName: '',
        selectOptions: [
          {text: "날짜", value: "date"}          
        ]        
      }
    },
    created() {
        window.onSelected = this.onSelected;
    },
    mounted() {
      this.init();
    },    
    methods: {
      init() {
        this.getDateList()
        //this.createList();
      },

      async getDateList()
      {
        const self = this;
        self.data.rows.splice(0);
        let orderNo = 0;
        const config = {
            method: 'get',
            url: `/logrecord/logdate`,
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${self.token}`
            }
        };
        const result = await axios(config);
        if (result.status === 200) {
          const logdates = result.data;
          //console.log("test====>");
          //console.log(logdates.length);         
          //console.log(logdates.at(0).logdate); 
         //////////////////////////////////
          for (let i = 0; i < logdates.length; i++) {
            orderNo++;
             var d = new Date(logdates[i].logdate);
           
            const formattedDate = this.formatDate(d);
            self.data.rows.push({
                orderNo: orderNo,                
                date: formattedDate,
                action: '<button class="btn-detail" onclick="onSelected(\'' + formattedDate + '\')">발급</button>'
            });            
          }
    
        }

      },

      addDays(date, days) {
        const clone = new Date(date);
        clone.setDate(date.getDate() + days)
        return clone;
      },

      createList() {
        const self = this;
        self.data.rows.splice(0);
        let orderNo = 0;

        const currentDate = new Date();
        //const startDate = new Date('2023-01-01');

        const startDate = this.addDays(currentDate, -365*3);

        while (currentDate >= startDate) {
            orderNo++;
            const formattedDate = this.formatDate(currentDate);
            self.data.rows.push({
                orderNo: orderNo,                
                date: formattedDate,
                action: '<button class="btn-detail" onclick="onSelected(\'' + formattedDate + '\')">발급</button>'
            });            
            currentDate.setDate(currentDate.getDate() - 1);
        }
      },
      

      formatDate(date) {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
      },      
      async getUserCount(date) {
        const self = this;
        const config = {
            method: 'get',
            url: `/logrecord/usercount?date=${date}`,
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${self.token}`
            }
        };

        const result = await axios(config);
        return result.data;
      },
      async onSelected(value) {  
        const self = this;      
        const userCount = await this.getUserCount(value);
        if (userCount[0].count < 1) {
            self.$swal.fire({title: '조회 실패', text: '해당 날짜에 데이터가 없습니다.', icon: 'error'})
            return;
        }        
        const selData = self.data.rows.find((element) => element.date === value)
        if (selData) {
            self.$router.push({name: 'logbookmodify', params: {selectedDate: selData.date, source: 'issue'}})
        }
      },
      getSelectValue(value) {
        const self = this;
        self.fieldName = value;
      },
  }
}
  </script>
  
  <style scoped>
  .main-container {
    padding: 15px;
  }
  
  .pageTitle {
    font-weight: bold;
  }
  
  .search-select {
    width: 150px;
  }

  /deep/ .btn-detail {
  border: 1px solid #2e56f4;
  background: #2e56f4;
  border-radius: 4px;
  font-weight: 500;
  font-size: 16px;
  color: #FFFFFF;
  padding: 0 16px;
}
  </style>
  