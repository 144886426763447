export default [
    {
        label: 'No.',
        field: 'orderNo',
        sort: true
    },
    {
        label: '날짜',
        field: 'date',
        sort: true
    },
    // {
    //     label: '교육생 수',
    //     field: 'userCount',
    //     sort: true
    // },
    {
        label: '',
        field: 'action',
        sort: false
    }
];
